import { Observable } from 'rxjs';
import { Injectable, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class TranslateConfigService {

  public selectedLanguage: string;

  constructor(private translate: TranslateService) {
    this.selectedLanguage = localStorage.getItem('@MetaTreino.linguagem');
    if (this.selectedLanguage) {
     this.setLanguage(this.selectedLanguage);
    }
  }

  getDefaultLanguage(){
    const language = this.translate.getBrowserLang();
    this.translate.setDefaultLang(language);
    this.selectedLanguage = language;
    return language;
  }

  setLanguage(setLang) {
    this.selectedLanguage = setLang;
    this.translate.use(setLang);
    localStorage.setItem('@MetaTreino.linguagem', setLang);
  }

  getLanguage() {

    if (!!this.selectedLanguage) {
      return this.selectedLanguage;
    } else {
      this.selectedLanguage = localStorage.getItem('@MetaTreino.linguagem');
      return this.selectedLanguage;
    }
  }

  getKey(chave: string): Observable<string> {
    return this.translate.get(chave);
  }
}
