import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  usuario: any = {};
  url = '/';

  constructor(
    private navCtrl: NavController,
    private router: Router
  ) { }

  ngOnInit() {
    this.url = this.router.url.split('?')[0];
    const ftoken: string = window.localStorage.getItem('@CoachControl.token');
    const ftipousuario = window.localStorage.getItem('@CoachControl.tipoUsuario');

    if (ftipousuario && ftoken) {
      this.usuario.nome = window.localStorage.getItem('@CoachControl.nomeUsuario');
      this.usuario.email = window.localStorage.getItem('@CoachControl.emailUsuario');
      this.usuario.idcoach = Number(window.localStorage.getItem('@CoachControl.idCoach'));
      this.usuario.tipo = ftipousuario;
      this.usuario.id = Number(window.localStorage.getItem('@CoachControl.idUsuario'));
    }
  }

  login() {
    this.navCtrl.navigateForward(`login`, { state: { url: 'home' } });
  }

  cadastrar() {
    this.navCtrl.navigateForward([`cadastrar`]);
  }

  logout() {
    localStorage.clear();
    this.navCtrl.navigateForward([`home`]);
    window.location.reload();
  }

  meusProgramas() {
    this.navCtrl.navigateForward([this.usuario.tipo ==='A' ? 'my-programs':'my-programs-coach']);
  }

  perfilCoach() {
    this.navCtrl.navigateForward(['perfil-coach']);
  }
}
